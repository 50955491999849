package org.evoleq.reakt.bulma.select

import kotlinx.html.id
import kotlinx.html.js.onChangeFunction
import org.evoleq.reakt.ReaktDsl
import org.w3c.dom.events.Event
import react.RBuilder
import react.ReactElement
import react.dom.div
import react.dom.option
import react.dom.select

data class SelectionData(
    val value: String,
    val showInitialOption: Boolean
)

@ReaktDsl
fun RBuilder.select(
    classes: String? = null,
    domId: String,
    initialOption: String,
    showInitialOption: Boolean,
    onChanged: (Event)->Unit,
    options: RBuilder.()->ReactElement
): ReactElement = div("select $classes".trim()){
    select {
        attrs {
            id = domId
            onChangeFunction = { onChanged(it) }
        }
        if(showInitialOption) {
            option {
                attrs {
                    value = "INIT"
                }
                +initialOption
            }
        }
        options()
    }
}