package org.solyton.solawi.management.application.page.bid.session.sessions

import org.evoleq.math.cat.adt.Maybe
import org.evoleq.math.cat.marker.MathCatDsl
import org.evoleq.reakt.FunctionalReaktProps
import org.solyton.solawi.management.module.bid.widget.managebidsession.BidSessionConfiguration
import react.RState

typealias BidSessionsDataProps = FunctionalReaktProps<BidSessionsData>

data class BidSessionsData(
    val data: List<BidSessionConfiguration> = listOf(),
    val meta: MetaData = MetaData()
): RState

data class MetaData(
    val componentState: ComponentState = ComponentState.Init
)

sealed class ComponentState {
    object Init : ComponentState()
    data class CreateBidSession(
        val maybeConfiguration: Maybe<BidSessionConfiguration>
    ) : ComponentState()
}

@MathCatDsl
fun BidSessionsData.meta(set: MetaData.()->MetaData): BidSessionsData = copy(
    meta = meta.set()
)

@MathCatDsl
fun BidSessionsData.data(set: List<BidSessionConfiguration>.()->List<BidSessionConfiguration>): BidSessionsData = copy(
    data = data.set()
)

@MathCatDsl
fun MetaData.componentState(set: ComponentState.()->ComponentState): MetaData = copy(
    componentState = componentState.set()
)
