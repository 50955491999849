package org.evoleq.reakt.bulma.expandable

import org.drx.dynamics.ID
import org.drx.evoleq.evolving.Evolving
import org.evoleq.reakt.FunctionalReaktProps
import react.RBuilder
import react.RState
import react.ReactElement

typealias ExpandableDataProps<Data> = FunctionalReaktProps<ExpandableData<Data>>

data class ExpandableData<Data>(
    val data: Data,
    val state: ExpandableState = ExpandableState.Closed,
    val headerContent: RBuilder.(data: ExpandableData<Data>, update: (ID, ExpandableData<Data>,)-> Evolving<ExpandableData<Data>>)-> ReactElement,
    val bodyContent: RBuilder.(data: ExpandableData<Data>, update: (ID, ExpandableData<Data>,)-> Evolving<ExpandableData<Data>>)-> ReactElement,
    val onStateChanged: (state: ExpandableState)->Unit = {}
): RState

sealed class ExpandableState{
    object Expanded : ExpandableState()
    object Closed : ExpandableState()
}