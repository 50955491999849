package org.solyton.solawi.management.application.router.bid.session.sessions

import org.drx.dynamics.ID
import org.evoleq.reakt.FunctionalReaktProps
import org.evoleq.reakt.ReaktDsl
import org.evoleq.reakt.*
import org.solyton.solawi.management.application.component.layout.LayoutProps
import org.solyton.solawi.management.application.config.Routes
import org.solyton.solawi.management.application.data.domain.AppData
import org.solyton.solawi.management.application.data.domain.userData
import org.solyton.solawi.management.application.page.bid.session.sessions.BidSessions
import org.solyton.solawi.management.application.page.bid.session.sessions.BidSessionsData
import org.solyton.solawi.management.application.page.bid.session.sessions.bidSessionsPage
import org.solyton.solawi.management.application.router.RoutingData
import org.solyton.solawi.management.module.user.data.domain.UserData
import react.RBuilder
import react.ReactElement
import react.router.dom.route



@ReaktDsl
fun RBuilder.bidSessionsRoute(
    routingData: RoutingData<AppData>
): ReactElement =
    route( Routes.bidSessions , exact = true ) {
        val layoutProps: LayoutProps.() -> Unit = {
            userData = routingData.data.userData
            roles = routingData.data.roles
            updateParent = { id: ID, data: UserData ->
                routingData.updateApplication(id) { appData: AppData ->
                    appData.userData { data }
                }
            }
        }
        val currentData = BidSessionsData(
        )
        val pageProps: FunctionalReaktProps<BidSessionsData>.()-> FunctionalReaktProps<BidSessionsData> = {
            id { BidSessions::class }.
            data { currentData }.
            forceUpdate{_,_-> true }.
            updateParent { id, update ->
                routingData.updateApplication(id) { data ->
                    with( update( currentData ) ) data@{
                        data
                    }
                }
            }/*.
            boundary {
                apis {
                    api {
                        name = TODO()
                        routes {
                            route {
                                name = TODO()
                                path = TODO()
                            }
                        }
                    }
                    browser {
                        routes {
                            route {
                                name = TODO()
                                path = TODO()
                            }
                        }
                    }
                }
            }
            */
        }
        bidSessionsPage(layoutProps to pageProps)
    }