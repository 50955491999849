package org.evoleq.reakt.bulma.modal

import org.drx.dynamics.ID
import org.drx.evoleq.evolving.Evolving
import org.evoleq.math.cat.marker.MathCatDsl
import org.evoleq.reakt.FunctionalReaktProps
import org.solyton.solawi.management.application.component.bulma.Type
import react.RBuilder
import react.RState
import react.ReactElement

typealias ModalDataProps<Data> = FunctionalReaktProps<ModalData<Data>>

data class ModalData<Data>(
    val data: Data,
    val domId: String,
    val title: String,
    val successButtonText: String,
    val cancelButtonText: String,
    val type: Type,
    val content: RBuilder.(data: ModalData<Data>, update: (ID, ModalData<Data>,)-> Evolving<ModalData<Data>>)-> ReactElement,
    val isActive: Boolean = true,
    val isValid: Boolean = true,
    val onCancel: () -> Unit = {},
    val onSuccess: ()->Unit = {}
): RState

@MathCatDsl
fun <Data> ModalData<Data>.data(set: Data.()->Data): ModalData<Data> = copy(
    data = data.set()
)


/*
val content: RBuilder.(data: ModalData<Unit>,update: (ID, ModalData<Unit>,)-> Evolving<ModalData<Unit>>)-> ReactElement =
    {
        data, update ->
        div{
            update(Int::class, data)
        }
    }
    
 */