package org.solyton.solawi.management.application.page.bid.session.sessions

import kotlinx.browser.document
import kotlinx.coroutines.CoroutineScope
import org.drx.dynamics.ID
import org.drx.evoleq.evolving.Evolving
import org.drx.evoleq.evolving.Parallel
import org.evoleq.math.cat.adt.Maybe
import org.evoleq.reakt.*
import org.evoleq.reakt.bulma.modal.Modal
import org.evoleq.reakt.bulma.modal.ModalData
import org.evoleq.reakt.bulma.modal.modal
import org.evoleq.reakt.bulma.select.select
import org.solyton.solawi.management.application.component.bulma.Size
import org.solyton.solawi.management.application.component.bulma.Type
import org.solyton.solawi.management.application.component.bulma.icon.fasIcon
import org.solyton.solawi.management.application.component.layout.LayoutProps
import org.solyton.solawi.management.application.component.layout.layout
import org.solyton.solawi.management.application.component.textfield.TextField
import org.solyton.solawi.management.application.component.textfield.TextFieldData
import org.solyton.solawi.management.application.component.textfield.TextFieldStatus
import org.solyton.solawi.management.application.component.textfield.textField
import org.solyton.solawi.management.application.service.debug.log
import org.solyton.solawi.management.module.bid.widget.managebidsession.*
import org.w3c.dom.HTMLSelectElement
import react.child
import react.RBuilder
import react.ReactElement
import react.dom.*


@ReaktDsl
fun RBuilder.bidSessionsPage(
   properties: Pair<LayoutProps.()->Unit, BidSessionsDataProps.()->BidSessionsDataProps>
): ReactElement = layout(properties.first) {
        bidSessions(properties.second)
    }

@ReaktDsl
fun RBuilder.bidSessions(
   setup: BidSessionsDataProps.()->BidSessionsDataProps
): ReactElement = child({
   props: BidSessionsDataProps->BidSessions(props.setup())
}){}

class BidSessions(
    override val properties: BidSessionsDataProps
): FunctionalReaktComponent<BidSessionsData>(
    properties
) {
    /**
     * OnUpdate function of the component
     */
    /*
    override fun onUpdate(senderId: ID, data: BidSessionsData): BidSessionsData {
        TODO()
    }
    
     */

    /**
     * Render function of the component
     */
    override fun RBuilder.render() {
        with(properties.data) {
            div("block"){
                div("button is-small is-primary is-pulled-right") {
                    fasIcon(
                        "plus",
                        size = Size.Large
                    ) {
                        update(BidSessions::class){ data ->
                            data.meta {
                                componentState {
                                    ComponentState.CreateBidSession(Maybe.Nothing)
                                }
                            }
                        }
                    }
                }
                h1("title is-3") {
                    +"Bieter-Runden"
                }
                data.forEach {
                
                }
                
                with(meta) {
                    when (componentState) {
                        is ComponentState.CreateBidSession -> createBidSessionModal(
                                componentState.maybeConfiguration,
                                ActiveInput.Benchmark,
                            ) { id, update -> this@BidSessions.update(id, update)
                        }
                        //else -> Unit
                    }
                }
        }
    }
}

sealed class ActiveInput{
    object Benchmark : ActiveInput()
    object TargetSum : ActiveInput()
}

class CreateBidSessionModal
private val createBidSessionModalId = "bid.session.create.modal"
@ReaktDsl
fun RBuilder.createBidSessionModal(
    maybeConfiguration: Maybe<BidSessionConfiguration>,
    activeInput: ActiveInput,
    updateParent: (ID,update: suspend CoroutineScope.(BidSessionsData) -> BidSessionsData) -> Evolving<BidSessionsData>
): ReactElement = modal<BidSessionConfiguration> {
    val modalData = ModalData(
        when(maybeConfiguration){
            is Maybe.Nothing -> BidSessionConfiguration()
            is Maybe.Just -> maybeConfiguration.value
        },
        createBidSessionModalId,
        "Neue Bieter-Runde anlegen",
        "Ok",
        "Abbrechen",
        Type.Info,
        content = {data, update -> div{
            val text = "${data.data.benchmark}"
            val benchmarkTextFieldData = TextFieldData(
                text,
                "bid.session.create.modal.benchmark",
                text.length,
                text.length,
                activeInput is ActiveInput.Benchmark,
                "Richtwert",
                when{
                    text.isDouble() -> TextFieldStatus.Valid
                    else -> TextFieldStatus.InValid
                },
                validateInput = {
                    isDouble()
                }
            )
            textField {
                id{
                    TextField::class
                }.data{
                    benchmarkTextFieldData
                }
            }
        }}
        
    )
    id{ CreateBidSessionModal::class }.
    data{ modalData }.
    updateParent { id, update ->  Parallel{with(update(this,modalData)) {
        val conf = this.data
        updateParent(id) {
            data ->
            /*data.data {
                listOf(
                    *this.toTypedArray(),
                    conf
                )
            }.
            
             */
            data.meta {
                componentState {
                    ComponentState.CreateBidSession(Maybe.ret(conf))
                }
            }
        }
    } } }
}
}