package org.evoleq.reakt.bulma.modal

import kotlinx.browser.document
import kotlinx.html.id
import kotlinx.html.js.onClickFunction
import org.drx.dynamics.ID
import org.evoleq.reakt.FunctionalReaktComponent
import org.evoleq.reakt.ReaktDsl
import org.evoleq.reakt.forceUpdate
import react.RBuilder
import react.ReactElement
import react.child
import react.dom.*

@ReaktDsl
fun <Data> RBuilder.modal(
   setup: ModalDataProps<Data>.()->ModalDataProps<Data>
): ReactElement = child({
   props: ModalDataProps<Data>->Modal<Data>(
    props.setup().forceUpdate { _, _ -> true }
)
}){}

sealed class ModalCommand {
    object Activate : ModalCommand()
    object DeActivate : ModalCommand()
    object EnableSuccessButton : ModalCommand()
    object DisableSuccessButton : ModalCommand()
    object DataChanged : ModalCommand()
}

class Modal<Data>(
    override val properties: ModalDataProps<Data>
): FunctionalReaktComponent<ModalData<Data>>(
    properties
) {
    
    
    
    /**
     * OnUpdate function of the component
     */
    override fun onUpdate(senderId: ID, data: ModalData<Data>): ModalData<Data> {
        return when(senderId) {
            ModalCommand.Activate::class -> data.copy(isActive = true)
            ModalCommand.DeActivate::class -> data.copy(isActive = false)
            ModalCommand.EnableSuccessButton::class -> data.copy(isValid = true)
            ModalCommand.DisableSuccessButton::class -> data.copy(isValid = false)
            ModalCommand.DataChanged::class -> with(data.copy(data = data.data)){
                properties.updateParent(properties.id){this@with}
                this
            }
            else -> data
        }
    }

    /**
     * Render function of the component
     */
    override fun RBuilder.render() {
        with(data()) data@{
            div(classes = "modal${when(isActive){
                true -> " is-active"
                false -> ""
            }}"
            ){
                attrs {
                    id = domId
                }
                div(classes = "modal-background"){}
                div(classes = "modal-card") {
                    header(classes = "modal-card-head  has-background$type"){
                        p(classes = "modal-card-title") {
                            +title
                        }
                        button(classes = "delete"){
                            attrs {
                                setProp("aria-label","close")
                                onClickFunction = {
                                    domId.deActivate()
                                    onCancel()
                                }
                            }
                        }
                    }
                    section(classes = "modal-card-body") {
                        content(this@data){ id: ID, data: ModalData<Data> -> update(id){data} }
                    }
                    footer(classes = "modal-card-foot") {
                        button(classes = "button is-success"){
                            +successButtonText
                            attrs {
                                id = "$domId.success-button"
                                disabled = !isValid
                                onClickFunction = {
                                    domId.deActivate()
                                    onSuccess()
                                }
                            }
                        }
                        button(classes = "button"){
                            +cancelButtonText
                            attrs {
                                onClickFunction = {
                                    domId.deActivate()
                                    onCancel()
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}



fun String.deActivate() = with(document.getElementById(this)!!)element@{
    val classes = getAttribute("class")
    setAttribute("class", classes?.replace("is-active", "")?.trim()?:"")
}

fun String.activate() = with(document.getElementById(this)!!){
    val classes = getAttribute("class")
    setAttribute("class", "${classes?:""} is-active".trim() )
}