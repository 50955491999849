package org.evoleq.reakt.bulma.expandable

import kotlinx.html.js.onClickFunction
import org.drx.dynamics.ID
import org.evoleq.reakt.FunctionalReaktComponent
import org.evoleq.reakt.ReaktDsl
import org.evoleq.reakt.forceUpdate
import react.RBuilder
import react.ReactElement
import react.child
import react.dom.div
import react.dom.i
import react.dom.span

@ReaktDsl
fun <Data> RBuilder.expandable(
   setup: ExpandableDataProps<Data>.()->ExpandableDataProps<Data>
): ReactElement = child({
   props: ExpandableDataProps<Data>->Expandable(props.setup().forceUpdate{_,_->true})
}){}

class Expandable<Data>(
    override val properties: ExpandableDataProps<Data>
): FunctionalReaktComponent<ExpandableData<Data>>(
    properties
) {
    
    class Toggle
    
    /**
     * OnUpdate function of the component
     */
    override fun onUpdate(senderId: ID, data: ExpandableData<Data>): ExpandableData<Data> {
        return when(senderId) {
            Toggle::class -> {
                properties.data.onStateChanged(data.state)
                data
            }
            else -> data
        }
    }

    /**
     * Render function of the component
     */
    override fun RBuilder.render() {
        val state = properties.data.state
        div("is-flex is-flex-direction-row full-width") {
            div {
                when (state) {
                    is ExpandableState.Closed -> div("button") {
                        div("is-hidden") {
                            span("icon") {
                                i("fas fa-angle-down") {}
                            }
                        }
                        div {
                            span("icon") {
                                i("fas fa-angle-right") {}
                            }
                        }
                    }
                    is ExpandableState.Expanded -> div("button") {
                        div {
                            span("icon") {
                                i("fas fa-angle-down") {}
                            }
                        }
                        div("is-hidden") {
                            span("icon") {
                                i("fas fa-angle-right") {}
                            }
                        }
                    }
                }
                attrs {
                    onClickFunction = {
                        update(Toggle::class) { data ->
                            data.copy(
                                state = when (state) {
                                    is ExpandableState.Closed -> ExpandableState.Expanded
                                    is ExpandableState.Expanded -> ExpandableState.Closed
                                }
                            )
                        }
                    }
                }
            }
    
            with(data()) data@{
                div("block full-width") {
                    div("block") {
                        headerContent(this@data) { id: ID, data: ExpandableData<Data> -> update(id) { data } }
                    }
                    when (state) {
                        is ExpandableState.Expanded -> div("block") {
                            bodyContent(this@data) { id: ID, data: ExpandableData<Data> -> update(id) { data } }
                        }
                        is ExpandableState.Closed -> Unit
                    }
                }
            }
        }
    }
}